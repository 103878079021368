import React, { useState } from "react"

import Layout from "../templates/Page"
import SwiperList from "../components/ContentBuilder/SwiperList"
import Header from "../components/ContentBuilder/Header"
import Subscribe from "../components/Footer/subscribe"

const room = [
  {
    id: 1,
    name: "Overral",
    url: "1",
  },
  {
    id: 2,
    name: "Bathroom",
    url: "2",
  },
  {
    id: 3,
    name: "Bedroom",
    url: "3",
  },
  {
    id: 4,
    name: "Kitchen",
    url: "4",
  },
  {
    id: 5,
    name: "External",
    url: "5",
  },
  {
    id: 6,
    name: "Butlenrs",
    url: "6",
  },
  {
    id: 7,
    name: "Laundry",
    url: "1",
  },
]

const StyleDetailPage = () => {
  const [title, setTitle] = useState(room[0].name)
  const [isReadmore, setReadmore] = useState(true)
  const [isViewmore, setViewmore] = useState(true)

  return (
    <Layout hideHeader>
      <Header isLogin />

      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/explore/">Explore</a>
            </li>
            <li className="breadcrumb-item">
            <a href="#">Homepage</a>
            </li>
            <li className="breadcrumb-item">
            <a href="#">Style</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Overral
            </li>
          </ol>
        </nav>

        <div className="mt-30 mb-30 border-bottom-7px">
          <div className="row align-items-center">
            <div className="col-md">
              <h1 className="product-name-title">Hamptons Range by Tiffany</h1>
            </div>
            <div className="col-md-auto">
              <button type="button" className="button-link">
                <div className="d-flex align-items-center">
                  Add to board<svg focusable="false" width='30px' height='30px' className="text-black ml-3"><use xlinkHref='/img/icons.svg#icon-heart'></use></svg>
                </div>
              </button>
            </div>
          </div>
        </div>

        <div className="wrap-section row">
          <div className="col-md-8">
            <h3 className="mb-4 border-bottom-4px">{title}</h3>
            <div className="row gutters-10">
              <div className="col-md-3">
                <div className="style-detail-functional">
                  {room.map(item => (
                    <button type="button"
                      className="btn btn-outline-black hover-primary btn-block btn-lg px-2"
                      key={item.id}
                      onClick={() => setTitle(item.name)}
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
              </div>
              <div className="col-md-9">
                <div style={{ height: 600, background: "#E5E5E5" }}>{title}</div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <h3 className="mb-1 border-bottom-4px">INFORMATION</h3>

            <div className="list-group list-group-flush mb-4">
              <div className="list-group-item px-0">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0">Price from</h5>
                  </div>
                  <div className="col-auto">
                    <p className="h4">$4199.00</p>
                  </div>
                </div>
              </div>
              <div className="list-group-item px-0">
                <div className="row">
                  <div className="col">
                    <h5 className="mb-0">Designer</h5>
                  </div>
                  <div className="col-auto">
                    <p className="font-weight-medium">Tiffany</p>
                  </div>
                </div>
              </div>
              <div className="list-group-item px-0">
                <h5 className="mb-0">About</h5>
                <p className="mb-2">The tap is designed to go hand-in-hand with a coloured sink from
                Blanco’s SILGRANIT range, but it will ultimately serve as a
                welcome addition to any.....</p>
                <div className="text-right">
                  <button type="button"
                    className="button-link"
                    onClick={() => setReadmore(!isReadmore)}
                  >
                    {isReadmore ? "Read more" : "Read less"}
                  </button>
                </div>
              </div>
            </div>

            <div className="d-flex flex-direction-column align-items-left mb-15">
              <h4 className="title-custom-design border-bottom-4px pb-2">Feature</h4>
              <div className="about-cell mt-15">
                <ul>
                  <li>Floating Vanities</li>
                  <li>Light grey floor tiles</li>
                  <li>Light grey carpert stairs</li>
                </ul>
              </div>
              <div className="text-right">
                <button type="button"
                  className="button-link"
                  onClick={() => setViewmore(!isViewmore)}
                >
                  {isViewmore ? "View more" : "View less"}
                </button>
              </div>
            </div>
            <div className="row gutters-10 mt-30">
              <div className="col-6">
                <button type="button" className="btn btn-outline-black btn-block btn-lg px-2">
                  <div className="d-flex align-items-middle justify-content-center">
                    <svg focusable="false" width='24px' height='24px' className="mr-2"><use xlinkHref='/img/icons.svg#icon-heart'></use></svg>
                    Add to board
                  </div>
                </button>
              </div>
              <div className="col-6">
                <button type="button" className="btn btn-black btn-block btn-lg px-2">Enquire now</button>
              </div>
            </div>
          </div>
        </div>

        <SwiperList title="Explore other styles" />
        <SwiperList title="Works with this cabinetry" />
        <SwiperList title="Works with these homes" />
        <SwiperList title="Works with these facades" />
        <SwiperList title="Related Furniture packs" />
      </div>
      
      <Subscribe></Subscribe>
    </Layout>
  )
}

export default StyleDetailPage
